import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 项目分页查询
 * @param params
 */
export function getProjectListPage(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/project/page',
        method: 'get',
        params
    })
}

/**
 * 项目列表查询
 * @param params
 */
export function getProjectList() {
    return request({
        url: envInfo.bgApp.consolePath + '/project/list',
        method: 'get',
    })
}


/**
* 修改项目
* @param params
*/
export function updateProjectList(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/project/update',
        method: 'post',
        data: params
    })
}


//查询单个项目
export function getOwnProject(params) {
    return request({
        url: envInfo.bgApp.consolePath + `/project/find/${params}`,
        method: 'get',
    })
}

/*
 * 新增项目
 * @param params
 */
export function insertProject(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/project/create',
        method: 'post',
        data: params
    })
}

/**
 * 根据项目ID查部门
 * @param {*} params
 */
export function getDeptByProject(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/project/findDeptListByProject',
        method: 'get',
        params
    })
}

/**
 * 删除人员
 * @param params
 */
export function deleteProjectList(param) {
    return request({
        url: envInfo.bgApp.consolePath + `/project/delete`,
        method: 'delete',
        data: param
    })
}

/**
 * 查询项目标签
 * @param params
 */
export function getProjectLabel() {
    return request({
        url: envInfo.bgApp.lookupPath + '/lookup/item/find/list?classifyCode=PROJECT_LABEL',
        method: 'get'
    })
}



