var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "app-container-empty" },
    [
      _c(
        "el-row",
        [
          _c(
            "el-col",
            { style: { width: "calc(20% - 5px)" } },
            [
              _c("el-card", { staticClass: "mapCard" }, [
                _c(
                  "div",
                  {
                    staticClass: "cardHeader",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c("title-icon"),
                    _c("span", { staticClass: "labelSpan" }, [
                      _vm._v(_vm._s(_vm.$t("iot.map.manager"))),
                    ]),
                    _c(
                      "el-tooltip",
                      {
                        attrs: { content: _vm.$t("iot.map.removeDeviceTips") },
                      },
                      [
                        _c("i", {
                          staticClass: "el-icon-question",
                          staticStyle: { "margin-left": "4px" },
                        }),
                      ]
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "el-form",
                      {
                        attrs: {
                          "label-position": "top",
                          "label-width": "285",
                        },
                      },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("iot.project.name") } },
                          [
                            _c(
                              "el-select",
                              {
                                style: { width: "100%" },
                                attrs: { filterable: "", clearable: "" },
                                on: { change: _vm.changeProject },
                                model: {
                                  value: _vm.presentProjectId,
                                  callback: function ($$v) {
                                    _vm.presentProjectId = $$v
                                  },
                                  expression: "presentProjectId",
                                },
                              },
                              _vm._l(_vm.projectList, function (item) {
                                return _c("el-option", {
                                  key: item.projectId,
                                  attrs: {
                                    value: item.projectId,
                                    label: item.projectName,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          { attrs: { label: _vm.$t("iot.position.name") } },
                          [
                            _c("tree-select", {
                              attrs: {
                                multiple: false,
                                "show-count": true,
                                "disable-branch-nodes": true,
                                placeholder: _vm.$t("commons.selectPlease"),
                                options: _vm.positionList,
                              },
                              on: { input: _vm.changePosition },
                              model: {
                                value: _vm.presentPositionId,
                                callback: function ($$v) {
                                  _vm.presentPositionId = $$v
                                },
                                expression: "presentPositionId",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "el-col",
            { style: { width: "calc(80% - 5px)", marginLeft: "10px" } },
            [
              _c("el-card", { staticClass: "mapCard" }, [
                _c(
                  "div",
                  {
                    staticClass: "cardHeader",
                    attrs: { slot: "header" },
                    slot: "header",
                  },
                  [
                    _c(
                      "el-row",
                      [
                        _c(
                          "el-col",
                          { attrs: { span: 16 } },
                          [
                            _c("title-icon"),
                            _vm._l(_vm.positionLabel, function (item, index) {
                              return [
                                _c(
                                  "span",
                                  { key: index, staticClass: "labelSpan" },
                                  [
                                    _vm.positionLabel.length - 1 == index
                                      ? [
                                          _c("span", [
                                            _vm._v(_vm._s(item.positionName)),
                                          ]),
                                        ]
                                      : 0 == index
                                      ? [
                                          _c("span", [
                                            _vm._v(_vm._s(item.positionName)),
                                          ]),
                                          _vm._v(
                                            " >\n                                    "
                                          ),
                                        ]
                                      : [
                                          _c(
                                            "a",
                                            {
                                              on: {
                                                click: function ($event) {
                                                  return _vm.clickPosition(
                                                    item.positionId
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v(_vm._s(item.positionName))]
                                          ),
                                          _vm._v(
                                            " >\n                                    "
                                          ),
                                        ],
                                  ],
                                  2
                                ),
                              ]
                            }),
                          ],
                          2
                        ),
                        _c("el-col", { attrs: { span: 4 } }),
                        _c(
                          "el-col",
                          { attrs: { span: 4 } },
                          [
                            _vm.isHaveMap
                              ? [
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: _vm.$t("iot.device.add"),
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "success",
                                          icon: "el-icon-plus",
                                        },
                                        on: { click: _vm.drawerClick },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: _vm.$t("iot.map.updateMap"),
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "warning",
                                          icon: "el-icon-upload",
                                        },
                                        on: { click: _vm.updateMap },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "el-tooltip",
                                    {
                                      attrs: {
                                        content: _vm.$t("commons.copyLink"),
                                      },
                                    },
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          type: "info",
                                          icon: "el-icon-link",
                                        },
                                        on: { click: _vm.copyLink },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              : _vm._e(),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c(
                      "vue-drag-resize",
                      {
                        style: {
                          width: _vm.mapWidth + "px",
                          height: _vm.mapHeight + "px",
                          position: "relative",
                        },
                        attrs: { isResizable: false },
                      },
                      [
                        _vm.presentPositionId != null
                          ? [
                              _vm.isHaveMap && !_vm.isUpdateMap
                                ? [
                                    _c(
                                      "el-image",
                                      {
                                        ref: "mapBackGround",
                                        staticStyle: { cursor: "move" },
                                        attrs: {
                                          src: _vm.getImage(
                                            _vm.map.form.mapImageId
                                          ),
                                        },
                                        on: {
                                          error: _vm.mapLoadError,
                                          load: _vm.updateMapSize,
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "no-data",
                                            attrs: { slot: "placeholder" },
                                            slot: "placeholder",
                                          },
                                          [
                                            _vm._v(
                                              "\n                                        " +
                                                _vm._s(
                                                  _vm.$t("iot.map.loading")
                                                )
                                            ),
                                            _c("span", { staticClass: "dot" }, [
                                              _vm._v("..."),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm._l(
                                      _vm.mapDeviceList,
                                      function (device, index) {
                                        return [
                                          _vm.presentCategoryId == null ||
                                          _vm.presentCategoryId ==
                                            device.categoryId
                                            ? [
                                                _c(
                                                  "vue-drag-resize",
                                                  {
                                                    key: index,
                                                    ref: "blockSon",
                                                    refInFor: true,
                                                    attrs: {
                                                      isResizable: false,
                                                      title: device.deviceName,
                                                      parentLimitation: true,
                                                      w: 50,
                                                      h: 50,
                                                      x: device.deviceLocationX,
                                                      y: device.deviceLocationY,
                                                    },
                                                    on: {
                                                      dragstop: function (
                                                        $event
                                                      ) {
                                                        return _vm.blockStop(
                                                          $event,
                                                          device,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "el-image",
                                                      {
                                                        staticStyle: {
                                                          cursor: "move",
                                                        },
                                                        attrs: {
                                                          src: _vm.getImage(
                                                            device.startPhoto
                                                          ),
                                                          onerror:
                                                            _vm.defaultDeviceIcon,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "no-data",
                                                            attrs: {
                                                              slot: "placeholder",
                                                            },
                                                            slot: "placeholder",
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                                                    " +
                                                                _vm._s(
                                                                  _vm.$t(
                                                                    "iot.map.loading"
                                                                  )
                                                                )
                                                            ),
                                                            _c(
                                                              "span",
                                                              {
                                                                staticClass:
                                                                  "dot",
                                                              },
                                                              [_vm._v("...")]
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            : _vm._e(),
                                        ]
                                      }
                                    ),
                                  ]
                                : [
                                    _c(
                                      "el-upload",
                                      {
                                        staticClass: "upload-demo",
                                        attrs: {
                                          "on-success": _vm.uploadSuccess,
                                          "on-error": _vm.uploadFail,
                                          "show-file-list": false,
                                          "with-credentials": true,
                                          drag: "",
                                          action: _vm.uploadPath,
                                        },
                                      },
                                      [
                                        _c("i", {
                                          staticClass: "el-icon-upload",
                                        }),
                                        _c(
                                          "div",
                                          { staticClass: "el-upload__text" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t("iot.map.uploadFile1")
                                              )
                                            ),
                                            _c("em", [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$t("iot.map.uploadFile2")
                                                )
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-drawer",
        {
          attrs: {
            size: "356px",
            visible: _vm.drawer,
            direction: _vm.direction,
          },
          on: {
            "update:visible": function ($event) {
              _vm.drawer = $event
            },
          },
        },
        [
          _c(
            "span",
            {
              staticClass: "labelSpan",
              attrs: { slot: "title" },
              slot: "title",
            },
            [
              _c("i", { staticClass: "el-icon-info" }),
              _vm._v(" " + _vm._s(_vm.$t("iot.map.addDeviceTips"))),
            ]
          ),
          _vm.map.form.mapImageId != null
            ? [
                _vm._l(_vm.notMapDeviceList, function (device, index) {
                  return [
                    _c(
                      "el-tooltip",
                      { key: index, attrs: { content: device.deviceName } },
                      [
                        _c(
                          "span",
                          {
                            key: index,
                            on: {
                              dblclick: function ($event) {
                                return _vm.addDeviceMap(device, index)
                              },
                            },
                          },
                          [
                            _c(
                              "el-image",
                              {
                                staticStyle: {
                                  width: "50px",
                                  height: "50px",
                                  margin: "10px",
                                  cursor: "pointer",
                                },
                                attrs: {
                                  src: _vm.getImage(device.startPhoto),
                                  onerror: _vm.defaultDeviceIcon,
                                },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "no-data",
                                    attrs: { slot: "placeholder" },
                                    slot: "placeholder",
                                  },
                                  [
                                    _vm._v(
                                      "\n                                " +
                                        _vm._s(_vm.$t("iot.map.loading"))
                                    ),
                                    _c("span", { staticClass: "dot" }, [
                                      _vm._v("..."),
                                    ]),
                                  ]
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                }),
              ]
            : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }