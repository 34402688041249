<template>
    <div class="app-container-empty">
        <el-row>
            <el-col :style="{width:'calc(20% - 5px)'}">
                <el-card class="mapCard">
                    <div slot="header" class="cardHeader">
                        <title-icon/><span class="labelSpan">{{$t('iot.map.manager')}}</span>
                        <el-tooltip :content="$t('iot.map.removeDeviceTips')">
                            <i class="el-icon-question" style="margin-left: 4px;"></i>
                        </el-tooltip>
                    </div>
                    <div>
                        <el-form label-position="top" label-width="285">
                            <el-form-item :label="$t('iot.project.name')">
                                <el-select @change="changeProject" v-model="presentProjectId" filterable clearable :style="{width:'100%'}">
                                    <el-option
                                            v-for="item in projectList"
                                            :key="item.projectId"
                                            :value="item.projectId"
                                            :label="item.projectName">
                                    </el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item :label="$t('iot.position.name')">
                                <tree-select
                                        v-model="presentPositionId"
                                        :multiple="false"
                                        :show-count="true"
                                        :disable-branch-nodes="true"
                                        :placeholder="$t('commons.selectPlease')"
                                        :options="positionList"
                                        @input="changePosition"/>
                            </el-form-item>
                        </el-form>
                    </div>
                </el-card>
            </el-col>
            <el-col :style="{width:'calc(80% - 5px)',marginLeft:'10px'}">
                <el-card class="mapCard">
                    <div slot="header" class="cardHeader">
                        <el-row>
                            <el-col :span="16">
                                <title-icon/>
                                <template v-for="(item,index) in positionLabel">
                                    <span :key="index" class="labelSpan">
                                        <template v-if="positionLabel.length - 1 == index">
                                            <span>{{item.positionName}}</span>
                                        </template>
                                        <template v-else-if="0 == index">
                                            <span>{{item.positionName}}</span> >
                                        </template>
                                        <template v-else>
                                            <a @click="clickPosition(item.positionId)">{{item.positionName}}</a> >
                                        </template>
                                    </span>
                                </template>
                            </el-col>
                            <el-col :span="4">
                            </el-col>
                            <el-col :span="4">
                                <template v-if="isHaveMap">
                                    <el-tooltip :content="$t('iot.device.add')">
                                        <el-button type="success" icon="el-icon-plus" @click="drawerClick"></el-button>
                                    </el-tooltip>
                                    <el-tooltip :content="$t('iot.map.updateMap')">
                                        <el-button type="warning" icon="el-icon-upload" @click="updateMap"></el-button>
                                    </el-tooltip>
                                    <el-tooltip :content="$t('commons.copyLink')">
                                        <el-button type="info" icon="el-icon-link" @click="copyLink"></el-button>
                                    </el-tooltip>
                                </template>
                            </el-col>
                        </el-row>
                    </div>
                    <div>
                        <vue-drag-resize :isResizable="false" :style="{width:mapWidth+'px',height:mapHeight+'px',position:'relative'}">
                            <template v-if="presentPositionId != null">
                                <template v-if="isHaveMap && !isUpdateMap">
                                    <el-image :src="getImage(map.form.mapImageId)" @error="mapLoadError" ref = "mapBackGround" @load="updateMapSize" style="cursor: move">
                                        <div slot="placeholder" class="no-data">
                                            {{ $t('iot.map.loading') }}<span class="dot">...</span>
                                        </div>
                                    </el-image>

                                    <template v-for="(device,index) in mapDeviceList">
                                        <template v-if="presentCategoryId==null || presentCategoryId == device.categoryId">
                                            <vue-drag-resize :isResizable="false" :key="index" :title="device.deviceName" :parentLimitation="true" :w="50" :h="50" :x="device.deviceLocationX" :y="device.deviceLocationY" ref="blockSon" v-on:dragstop="blockStop($event,device,index)">
                                                <el-image :src="getImage(device.startPhoto)" :onerror="defaultDeviceIcon" style="cursor: move">
                                                    <div slot="placeholder" class="no-data">
                                                        {{ $t('iot.map.loading') }}<span class="dot">...</span>
                                                    </div>
                                                </el-image>
                                            </vue-drag-resize>
                                        </template>
                                    </template>
                                </template>
                                <template v-else>
                                    <el-upload
                                            class="upload-demo"
                                            :on-success="uploadSuccess"
                                            :on-error = "uploadFail"
                                            :show-file-list="false"
                                            :with-credentials="true"
                                            drag
                                            :action="uploadPath">
                                        <i class="el-icon-upload"></i>
                                        <div class="el-upload__text">{{$t('iot.map.uploadFile1')}}<em>{{$t('iot.map.uploadFile2')}}</em></div>
                                    </el-upload>
                                </template>
                            </template>
                        </vue-drag-resize>
                    </div>
                </el-card>
            </el-col>
        </el-row>

        <el-drawer
                size="356px"
                :visible.sync="drawer"
                :direction="direction">
            <span class="labelSpan" slot="title"><i class="el-icon-info"></i> {{$t('iot.map.addDeviceTips')}}</span>
            <template v-if="map.form.mapImageId != null">
                <template v-for="(device,index) in notMapDeviceList">
                    <el-tooltip :key="index" :content="device.deviceName">
                        <span :key="index" @dblclick="addDeviceMap(device,index)">
                            <el-image :src="getImage(device.startPhoto)" :onerror="defaultDeviceIcon" style="width: 50px;height:50px;margin:10px;cursor: pointer;">
                                <div slot="placeholder" class="no-data">
                                    {{ $t('iot.map.loading') }}<span class="dot">...</span>
                                </div>
                            </el-image>
                        </span>
                    </el-tooltip>
                </template>
            </template>
        </el-drawer>
    </div>
</template>

<script>
    import {
        getPositionMap,
        getNotMapDevice,
        getMapDevice,
        createDeviceMap,
        updateDeviceMap,
        deleteDeviceMap,
        updateMap,
        createMap,
    } from '@/api/ruge/iot/map/map'
    import { getPositionTree } from '@/api/ruge/iot/position/position'
    import { getProjectList } from '@/api/ruge/iot/project/project'
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import {listToTree} from '@/utils/tree'
    import VueDragResize from 'vue-drag-resize';
    import { envInfo } from '@/constants/envInfo'
    import TreeSelect from '@riophae/vue-treeselect';
    import '@riophae/vue-treeselect/dist/vue-treeselect.css'
    import { copy2Clip } from '@/utils/global'

    export default {
        name: "MapManager",
        components: {
            VueDragResize,
            TreeSelect
        },
        data() {
            return {
                // 默认设备图标
                defaultDeviceIcon: 'this.src="' + require("@/assets/images/monitor.png") + '"',
                uploadPath:envInfo.bgApp.archivePath+'/param/archive/upload?ulType=DefaultUpload',
                projectList:[],
                positionList:[],
                positionId:null,
                mapWidth:360,
                mapHeight:180,
                deviceLoading:false,
                drawer:false,
                direction: 'rtl',
                //当前位置是否关联地图
                isHaveMap:false,
                //是否更换地图
                isUpdateMap:false,
                presentProjectId:null,
                presentPositionId:null,
                presentCategoryId:null,
                //不在地图中的设备
                notMapDeviceList:[],
                //地图中的设备
                mapDeviceList:[],
                positionMap:[],
                positionLabel:[{positionName:this.$t('iot.map.selectPosition')}],
                deviceMap:{
                    form:{
                        mapDeviceId :null,
                        mapId:null,
                        deviceId:null,
                        deviceName:null,
                        categoryName:null,
                        deviceLocationX:null,
                        deviceLocationY:null,
                        onOff:null,
                        monitorCode:null,
                        startPhoto:null,
                        stopPhoto:null,
                        categoryId:null,
                        deviceType:null,
                        monitorType:null,
                        monitorLevel:null,
                        isAuto:null,
                        ifAlarm:null,
                        monitorPath:null,
                        positionId:null,
                        monitorList:null,
                    }
                },
                map:{
                    form:{
                        mapId:null,
                        mapImageId:null,
                        positionId:null
                    }
                }
            }
        },
        created() {
            this.findProjectList();
        },
        methods:{
            findProjectList(){
                getProjectList().then(response =>{
                    this.projectList = response;
                });
            },
            changeProject() {
                if(this.presentProjectId == ''){
                    this.presentProjectId = null;
                    this.presentPositionId = null;
                    this.positionList = [];
                }else{
                    getPositionTree({"projectId":this.presentProjectId}).then(response =>{
                        this.positionList = [];
                        this.presentPositionId = null;
                        let obj = this.projectList.find((item)=>{
                            return item.projectId === this.presentProjectId;//筛选出匹配数据
                        });
                        this.positionMap = new Map();
                        response.push({"positionId":-1,"positionName":obj.projectName})
                        let data = listToTree(response,"positionId","parentId");
                        this.recursivePosition(data)
                    });
                }
            },
            //递归修改属性及数据结构
            recursivePosition(row) {
                let data = row.map(v => {
                    this.positionMap.set(v.positionId,v)
                    v.label = v.positionName;
                    v.id = v.positionId;
                    if(v.children != undefined){
                        this.recursivePosition(v.children);
                    }
                    return v
                })
                this.positionList = data;
            },
            drawerClick(){
                if (!this.drawer) {
                    this.drawer = true;
                    this.getNotMapDevice(this.presentPositionId);
                }
            },
            clickPosition(positionId){
                if(positionId != undefined){
                    this.presentPositionId = positionId;
                }
            },
            changePosition(){
                if(this.presentPositionId != undefined){
                    let positionNode = this.positionMap.get(this.presentPositionId);
                    if(positionNode.parentId != -999){
                        this.positionLabel = [positionNode];
                        this.findParentName(positionNode);
                        this.positionLabel = this.positionLabel.reverse();
                        //获取位置地图信息
                        this.getPositionMapInfo(positionNode.positionId);
                    }else{
                        this.$message({
                            message: this.$t('iot.map.selectPosition'),
                            type: 'info'
                        });
                    }
                }
            },
            //地图加载错误回调
            mapLoadError(){
                this.mapWidth=1822;
                this.mapHeight= 568;
            },
            //获取子块的XY坐标
            blockStop(newRect,device,index){
                if(newRect.left == 0 || newRect.top ==0){
                    this.$confirm(this.$t('iot.map.deleteMapConfirm'), this.$t('commons.warning'), {
                        confirmButtonText: this.$t('commons.confirm'),
                        cancelButtonText: this.$t('commons.cancel'),
                        type: 'warning'
                    }).then(() => {
                        deleteDeviceMap({"mapDeviceId":device.mapDeviceId}).then(()=>{
                            // 成功提示
                            this.$message({
                                message: this.$t('message.deleteSuccess'),
                                type: 'success'
                            });
                            //移除当前在地图中的设备
                            this.mapDeviceList.splice(index, 1);
                            this.getNotMapDevice(this.presentPositionId);
                        });
                    }).catch(error => {
                        newRect.left = device.deviceLocationX;
                        newRect.top = device.deviceLocationY;
                        console.log(`未删除，原因 => ${error}`)
                    });
                }else{
                    //更新地图设备位置
                    let params = {
                        deviceLocationX: newRect.left,
                        deviceLocationY: newRect.top,
                        mapDeviceId: device.mapDeviceId
                    };
                    updateDeviceMap(params).then(()=>{
                        this.getMapDevice(device.mapId)
                    });
                }
            },
            //点击位置获取位置地图信息
            clickNode(data,node){
                if(data.parentId != -999){
                    let positionLabel = node.label;
                    this.findParentName(node,positionLabel);
                    //获取位置地图信息
                    this.getPositionMapInfo(data.positionId);
                }else{
                    this.$message({
                        message: this.$t('iot.map.selectPosition'),
                        type: 'info'
                    });
                }
            },
            //获取位置地图信息
            getPositionMapInfo(positionId){
                //获取地图信息
                getPositionMap({"positionId":positionId}).then(obj =>{
                    this.presentPositionId = positionId;
                    //是否存在位置地图
                    if(obj != ""){
                        //存在地图
                        this.isUpdateMap = false;
                        this.isHaveMap = true;
                        this.map.form = obj;
                        //查询不在地图中的设备
                        this.getNotMapDevice(positionId)
                    }else{
                        //不存在地图
                        this.isHaveMap = false;
                        this.map.form = {
                            mapId:null,
                            mapImageId:null,
                            positionId:null
                        };
                        this.mapWidth = 360;
                        this.mapHeight = 180;
                    }
                });
            },
            //查询不在地图中的设备
            getNotMapDevice(positionId){
                this.deviceLoading = true;
                getNotMapDevice({"positionId":positionId,"categoryId":0}).then(deviceList =>{
                    this.notMapDeviceList = deviceList;
                    this.deviceLoading = false;
                });
            },
            //查询在地图中的设备
            getMapDevice(mapId){
                getMapDevice({"mapId":mapId,"categoryId":0}).then(deviceList =>{
                    this.mapDeviceList = deviceList;
                });
            },
            //获取图片
            getImage(fileId){
                if(!fileId) {
                    return "";
                }
                return envInfo.bgApp.archivePath + "/param/archive/download?dlType=DefaultDownload&fType=image&fi=" + fileId;
            },
            //地图加载完成回调
            updateMapSize(){
                let obj = this.$refs;
                if(obj.mapBackGround != undefined){
                    let mapWidth = obj.mapBackGround.imageWidth;
                    let mapHeight = obj.mapBackGround.imageHeight;
                    this.mapWidth = mapWidth;
                    this.mapHeight = mapHeight;
                }
                //查询在地图中的设备
                this.getMapDevice(this.map.form.mapId)
            },
            //递归获取位置坐标
            findParentName(node){
                let parentNode = this.positionMap.get(node.parentId);
                if(parentNode != null){
                    this.positionLabel.push(parentNode);
                    this.findParentName(parentNode);
                }
            },
            //添加设备到地图中
            addDeviceMap(device,index){
                let params = {
                    deviceId: device.deviceId,
                    mapId: this.map.form.mapId,
                    deviceLocationX:0,
                    deviceLocationY:0
                };
                createDeviceMap(params).then(() =>{
                    //移除当前不在地图的设备
                    this.notMapDeviceList.splice(index, 1);
                    //查询在地图中的设备
                    this.getMapDevice(this.map.form.mapId)
                });
            },
            //地图图片上传成功回调
            uploadSuccess(files){
                if(this.isHaveMap){
                    updateMap({
                        "mapId": this.map.form.mapId,
                        "mapImageId":files[0].fileId,
                        "positionId":this.presentPositionId
                    }).then(() =>{
                        this.getPositionMapInfo(this.presentPositionId);
                        this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
                    });
                }else{
                    createMap({
                        "mapImageId":files[0].fileId,
                        "positionId":this.presentPositionId
                    }).then(() =>{
                        this.getPositionMapInfo(this.presentPositionId);
                        this.$message({type: 'success', message: this.$t('message.uploadSuccess')});
                    });
                }
            },
            //地图图片上传失败回调
            uploadFail(){
                this.$message({type: 'error', message: this.$t('message.uploadFial')});
            },
            //更换地图
            updateMap(){
                this.isUpdateMap = true;
            },
            /**
             * 复制地图链接到粘贴板
             */
            copyLink() {
                let content = "/map/exhibition?toolTop=0&toolRight=0&minZoom=50&maxZoom=200&autoChange=0";
                if(this.presentPositionId != null || this.presentPositionId != undefined){
                    content += "&positionId="+this.presentPositionId;
                }
                if(this.presentCategoryId != null || this.presentCategoryId != undefined){
                    content += "&categoryId="+this.presentCategoryId;
                }
                copy2Clip(content);
            }
        }
    }
</script>

<style scoped>
    .mapCard >>> .el-card__header {
        padding: 6px 10px;
        line-height: 34px;
        height: 48px;
    }

    .mapCard >>> .el-card__body {
        padding: 15px;
        height: calc(100vh - 140px);
        overflow: scroll;
    }

    .cardHeader {
        line-height: 34px;
        font-size: 14px;
    }

    .labelSpan{
        font-weight: 500;
        -webkit-font-smoothing: antialiased;
        text-rendering: optimizeLegibility;
        font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;
    }

    .labelSpan >>> a:hover{
        color: #409EFF;
    }
</style>
