import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'
import {downTemplate} from '@/utils/download'

/**
 * 位置列表查询
 * @param params
 */
export function getPositionById(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/position/get/'+params.positionId,
        method: 'get',
    })
}

/**
 * 创建位置
 * @param params
 */
export function createPosition(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/position/create',
        method: 'post',
        data: params
    })
}

/**
 * 更新位置
 * @param params
 */
export function updatePosition(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/position/update',
        method: 'post',
        data: params
    })
}

/**
 * 删除位置
 * @param params
 */
export function deletePosition(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/position/delete',
        method: 'delete',
        data : params
    })
}

/**
 * 导出位置
 * @param params
 */
export function exportPosition(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/position/export',
        method: 'get',
        params
    })
}

/**
 * 下载设备模版
 */
export function downEmployeeTemplate() {
    downTemplate("ruge.position", envInfo.bgApp.consolePath);
}

/**
 * 查询位置标签
 * @param params
 */
export function findPositionLabel(params) {
    return request({
        url: envInfo.bgApp.lookupPath + `/lookup/item/find/list?classifyCode=${params.classifyCode}`,
        method: 'get',
    })
}

/**
 * 查询位置树
 */
export function getPositionTree(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/position/findPositionTree',
        method: 'get',
        params
    })
}
