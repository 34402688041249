import request from '@/utils/request'
import { envInfo } from '@/constants/envInfo'

/**
 * 查询对应位置地图信息
 * @param params
 */
export function getPositionMap(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/getPosition/'+params.positionId,
        method: 'get',
    })
}

/**
 * 更新地图
 * @param params
 */
export function updateMap(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/update',
        method: 'post',
        data : params
    })
}

/**
 * 创建地图
 * @param params
 */
export function createMap(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/create',
        method: 'post',
        data : params
    })
}

/**
 * 查询不在位置地图中的设备
 * @param params
 */
export function getNotMapDevice(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/device/findNotInMapOfDeviceList',
        method: 'get',
        params
    })
}

/**
 * 查询在位置地图中的设备
 * @param params
 */
export function getMapDevice(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/device/findDevicesByType',
        method: 'get',
        params
    })
}

/**
 * 根据设备类型查询设备信息
 * @param params
 */
export function getMapDeviceList(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/device/findDevicesByType',
        method: 'get',
        params
    })
}

/**
 * 添加地图设备
 * @param params
 */
export function createDeviceMap(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/device/create',
        method: 'post',
        data: params
    })
}

/**
 * 更新地图设备位置
 * @param params
 */
export function updateDeviceMap(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/device/update',
        method: 'post',
        data: params
    })
}


/**
 * 删除地图设备
 * @param params
 */
export function deleteDeviceMap(params) {
    return request({
        url: envInfo.bgApp.consolePath + '/map/device/delete/'+params.mapDeviceId,
        method: 'delete',
    })
}
